<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import ContactForm from '@/components/assistance/ContactForm.vue'
import FAQContent from '@/components/assistance/FAQContent.vue'
import UserGuide from '@/components/assistance/UserGuide.vue'
import Breadcrumb from 'primevue/breadcrumb'

type Props = {
  visible: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:visible'])

const visible = useVModel(props, 'visible', emit)

const currentView = ref(null as null | 'contact' | 'faq' | 'guide')
const breadcrumbHome = ref({
  icon: 'pi pi-home',
})
const breadcrumbItems = computed(() => {
  if (currentView.value === 'contact') {
    return [{ label: 'Nous contacter' }]
  } else if (currentView.value === 'faq') {
    return [{ label: 'FAQ' }]
  } else if (currentView.value === 'guide') {
    return [{ label: 'Guide utilisateur' }]
  }
  return []
})
const contentClass = computed(() => {
  if (currentView.value === 'guide') return 'overflow-hidden'
  else if (currentView.value === 'faq') return 'modal-max-width'
  return ''
})

watch(
  visible,
  () => {
    currentView.value = null
  },
  { immediate: true },
)

const changeCurrentView = (view?: 'contact' | 'faq' | 'guide') => {
  if (view) currentView.value = view
  else currentView.value = null
}

const resetCurrentView = () => {
  currentView.value = null
}
</script>
<template>
  <Dialog v-model:visible="visible" modal header="Assistance" :contentClass="contentClass" maximizable>
    <div v-if="currentView == null" class="grid grid-nogutter gap-3 row-gap-3 justify-content-center">
      <div class="col-12 md:col-6 text-center">
        <div
          class="flex flex-column row-gap-3 justify-content-center p-3 border-solid border-2 shadow-5 border-round-md cursor-pointer"
          @click="changeCurrentView('guide')"
          v-tippy="'Accéder au guide utilisateur'"
        >
          <i class="pi pi-book text-6xl" />
          <b>Guide utilisateur</b>
        </div>
      </div>
      <div class="col-12 md:col-6 text-center">
        <div
          class="flex flex-column row-gap-3 justify-content-center p-3 border-solid border-2 shadow-5 border-round-md cursor-pointer"
          @click="changeCurrentView('faq')"
          v-tippy="'Accéder à la FAQ'"
        >
          <i class="pi pi-question text-6xl" />
          <b>FAQ</b>
        </div>
      </div>
      <span class="col-12 text-center">ou</span>
      <div class="col-12 md:col-6 text-center">
        <div
          class="flex flex-column row-gap-3 justify-content-center p-3 border-solid border-2 shadow-5 border-round-md cursor-pointer"
          @click="changeCurrentView('contact')"
          v-tippy="'Accéder au formulaire de contact'"
        >
          <i class="pi pi-envelope text-6xl" />
          <b>Nous contacter</b>
        </div>
      </div>
    </div>
    <div v-else>
      <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" class="pt-0">
        <template #item="{ item }">
          <span class="cursor-pointer" @click.stop="resetCurrentView()">
            <i :class="item.icon" />
            {{ item.label }}
          </span>
        </template>
      </Breadcrumb>
      <ContactForm v-if="currentView === 'contact'" @close="visible = false" />
      <FAQContent
        v-else-if="currentView === 'faq'"
        @consultGuide="currentView = 'guide'"
        @contactUs="currentView = 'contact'"
      />
      <UserGuide
        v-else-if="currentView === 'guide'"
        @consultFaq="currentView = 'faq'"
        @contactUs="currentView = 'contact'"
      />
    </div>
  </Dialog>
</template>
