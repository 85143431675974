import { createRouter, createWebHistory } from 'vue-router'
import { Router } from '@/models/router.model'
import { useMainStore } from '@/stores/main'
import { useCompanyStore } from '@/stores/company'
import { useDeclarationStore } from '@/stores/declaration'

const AccountPage = () => import('@/views/account/MyAccount.vue')
const IdentifiersPage = () => import('@/views/account/MyIdentifiers.vue')
const SubscriptionsPage = () => import('@/views/account/MySubscriptions.vue')
const BillsPage = () => import('@/views/account/MyBills.vue')
const CompaniesPage = () => import('@/views/account/MyCompanies.vue')
const SettingsPage = () => import('@/views/account/MySettings.vue')
const GeneralInformationsStep = () => import('@/views/company/GeneralInformations.vue')
const OrganizationStep = () => import('@/views/company/SocialOrganizations.vue')
const ContactStep = () => import('@/views/company/Contact.vue')
const WorkAccidentStep = () => import('@/views/company/WorkAccident.vue')
const TaxationStep = () => import('@/views/company/Taxation.vue')
const EmployeeRecord = () => import('@/views/employee/EmployeeRecord.vue')
const EmployeeForm = () => import('@/views/employee/EmployeeForm.vue')
const CompanyForm = () => import('@/views/company/CompanyForm.vue')
const GeneralInformationsContractStep = () => import('@/views/contract/GeneralInformations.vue')
const ContractForm = () => import('@/views/contract/ContractForm.vue')
const EmployeeList = () => import('@/views/employee/Employees.vue')
const StatusStep = () => import('@/views/contract/StatusSpecifications.vue')
const CategoryStep = () => import('@/views/contract/Category.vue')
const WorkingHoursAndWages = () => import('@/views/contract/WorkingHoursAndWages.vue')
const AdditionalInformationsStep = () => import('@/views/contract/AdditionalInformations.vue')
const ComplementaryOrgMemberShipStep = () => import('@/views/contract/ComplementaryOrgMembership.vue')
const DashboardHome = () => import('@/views/dashboard/DashboardHome.vue')
const DeclarationsTab = () => import('@/views/dashboard/Declarations.vue')
const OrganizationsPaymentsTab = () => import('@/views/dashboard/OrganizationsPayments.vue')
const SalariesPaidTab = () => import('@/views/dashboard/SalariesPaid.vue')
const DeclarationSummary = () => import('@/views/declaration/DeclarationSummary.vue')
const IndividualDeclaration = () => import('@/views/declaration/IndividualDeclaration.vue')
const CompanyDeclaration = () => import('@/views/declaration/CompanyDeclaration.vue')
const GlobalInfosEndingContractStep = () => import('@/views/reporting/GlobalInfosEndingContractStep.vue')
const EndingContractForm = () => import('@/views/reporting/EndingContractForm.vue')
const LastMonthRevenuStep = () => import('@/views/reporting/LastMonthRevenuStep.vue')
const ComplementaryInfosStep = () => import('@/views/reporting/ComplementaryInfosStep.vue')
const ReportingWorkingStoppage = () => import('@/views/reporting/ReportingWorkingStoppage.vue')
const ReportsTab = () => import('@/views/dashboard/Reports.vue')
const SubscriptionPage = () => import('@/views/subscription/Subscription.vue')
const RegularizationDeclaration = () => import('@/views/declaration/RegularizationDeclaration.vue')
const RecapsPage = () => import('@/views/dashboard/Recaps.vue')

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: Router.HOME,
      redirect: Router.DASHBOARD + Router.DECLARATIONS,
    },
    {
      path: '/abonnement',
      name: Router.SUBSCRIBE,
      component: SubscriptionPage,
      meta: {
        needSelectedCompany: true,
        mustReturnHomeIfCompanyChanged: true,
      },
    },
    {
      path: '/mon-compte',
      name: Router.MY_ACCOUNT,
      component: AccountPage,
      children: [
        {
          path: '',
          name: Router.MY_COMPANIES + '_REDIRECT',
          redirect: Router.MY_ACCOUNT + Router.MY_COMPANIES,
        },
        {
          path: 'mes-identifiants',
          name: Router.MY_IDENTIFIERS,
          component: IdentifiersPage,
          meta: {
            needSelectedCompany: false,
          },
        },
        {
          path: 'mes-abonnements',
          name: Router.MY_SUBSCRIPTIONS,
          component: SubscriptionsPage,
          meta: {
            needSelectedCompany: false,
          },
        },
        {
          path: 'mes-factures',
          name: Router.MY_BILLS,
          component: BillsPage,
          meta: {
            needSelectedCompany: false,
          },
        },
        {
          path: 'mes-entreprises',
          name: Router.MY_COMPANIES,
          component: CompaniesPage,
          meta: {
            needSelectedCompany: false,
          },
        },
        {
          path: 'mes-parametres',
          name: Router.MY_SETTINGS,
          component: SettingsPage,
          meta: {
            needSelectedCompany: false,
          },
        },
      ],
    },
    {
      path: '/entreprise',
      name: Router.COMPANY,
      meta: {
        needSelectedCompany: false,
      },
      component: CompanyForm,
      children: [
        {
          path: '',
          name: Router.COMPANY + '_REDIRECT',
          component: GeneralInformationsStep,
          meta: {
            needSelectedCompany: false,
            isFirstStep: true,
            shouldSaveForm: true,
          },
        },
        {
          path: 'informations-generales',
          name: Router.STEP_GENERAL_INFORMATIONS,
          component: GeneralInformationsStep,
          meta: {
            needSelectedCompany: false,
            isFirstStep: true,
            shouldSaveForm: true,
          },
        },
        {
          path: 'contact',
          name: Router.STEP_CONTACT,
          component: ContactStep,
          meta: {
            needSelectedCompany: true,
            shouldSaveForm: true,
          },
        },
        {
          path: 'organismes-sociaux',
          name: Router.STEP_SOCIAL_ORGANIZATIONS,
          component: OrganizationStep,
          meta: {
            needSelectedCompany: true,
            shouldSaveForm: true,
          },
        },
        {
          path: 'fiscalite',
          name: Router.STEP_TAXATION,
          component: TaxationStep,
          meta: {
            needSelectedCompany: true,
            shouldSaveForm: true,
          },
        },
        {
          path: 'accident-du-travail',
          name: Router.STEP_WORK_ACCIDENT,
          component: WorkAccidentStep,
          meta: {
            needSelectedCompany: true,
            isLastStep: true,
            shouldSaveForm: true,
          },
        },
      ],
    },
    {
      path: '/salarie',
      name: Router.EMPLOYEE,
      component: EmployeeRecord,
      meta: {
        needSelectedCompany: true,
        mustReturnHomeIfCompanyChanged: true,
      },
      children: [
        {
          path: '',
          name: Router.EMPLOYEE + 'infos',
          component: EmployeeForm,
          meta: {
            needSelectedCompany: true,
            mustReturnHomeIfCompanyChanged: true,
            shouldSaveForm: true,
          },
        },
        {
          path: 'contrat',
          name: Router.CONTRACT,
          component: ContractForm,
          children: [
            {
              path: '',
              name: Router.CONTRACT + '-info',
              component: GeneralInformationsContractStep,
              meta: {
                isFirstStep: true,
                needSelectedCompany: true,
                mustReturnHomeIfCompanyChanged: true,
                shouldSaveForm: true,
              },
            },
            {
              path: 'informations-generales',
              name: Router.STEP_GENERAL_INFORMATIONS + '-contrat',
              component: GeneralInformationsContractStep,
              meta: {
                isFirstStep: true,
                needSelectedCompany: true,
                mustReturnHomeIfCompanyChanged: true,
                shouldSaveForm: true,
              },
            },
            {
              path: 'status',
              name: Router.STEP_STATUS,
              component: StatusStep,
              meta: {
                needSelectedCompany: true,
                mustReturnHomeIfCompanyChanged: true,
                shouldSaveForm: true,
              },
            },
            {
              path: 'categorie',
              name: Router.STEP_CATEGORY,
              component: CategoryStep,
              meta: {
                needSelectedCompany: true,
                mustReturnHomeIfCompanyChanged: true,
                shouldSaveForm: true,
              },
            },
            {
              path: 'temps-de-travail-et-salaire',
              name: Router.STEP_WORKING_HOURS_WAGES,
              component: WorkingHoursAndWages,
              meta: {
                needSelectedCompany: true,
                mustReturnHomeIfCompanyChanged: true,
                shouldSaveForm: true,
              },
            },
            {
              path: 'affiliation-organismes-complementaires',
              name: Router.COMPLEMENTARY_ORG_MEMBERSHIP,
              component: ComplementaryOrgMemberShipStep,
              meta: {
                needSelectedCompany: true,
                mustReturnHomeIfCompanyChanged: true,
                shouldSaveForm: true,
              },
            },
            {
              path: 'informations-complementaires',
              name: Router.STEP_COMPLEMENTARY_INFORMATIONS,
              component: AdditionalInformationsStep,
              meta: {
                isLastStep: true,
                needSelectedCompany: true,
                mustReturnHomeIfCompanyChanged: true,
                shouldSaveForm: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/tableau-de-bord',
      name: Router.DASHBOARD,
      component: DashboardHome,
      meta: {
        needSelectedCompany: true,
      },
      children: [
        {
          path: '',
          name: Router.DECLARATIONS + '_REDIRECT',
          redirect: Router.DASHBOARD + Router.DECLARATIONS,
        },
        {
          path: 'declarations',
          name: Router.DECLARATIONS,
          component: DeclarationsTab,
          meta: {
            needSelectedCompany: true,
          },
        },
        {
          path: 'salaries',
          name: Router.EMPLOYEES,
          component: EmployeeList,
          meta: {
            needSelectedCompany: true,
          },
        },
        {
          path: 'signalements',
          name: Router.REPORTS,
          component: ReportsTab,
          meta: {
            needSelectedCompany: true,
          },
        },
        {
          path: 'recapitulatifs',
          name: Router.RECAPS,
          component: RecapsPage,
          meta: {
            needSelectedCompany: true,
          },
        },
        {
          path: 'paiements-organismes',
          name: Router.ORGANIZATIONS_PAYMENTS,
          component: OrganizationsPaymentsTab,
          meta: {
            needSelectedCompany: true,
          },
        },
        {
          path: 'salaires',
          name: Router.SALARIES_PAID,
          component: SalariesPaidTab,
          meta: {
            needSelectedCompany: true,
          },
        },
      ],
    },
    {
      path: '/declaration',
      name: Router.DECLARATION_SUMMARY,
      meta: {
        needSelectedCompany: true,
        mustReturnHomeIfCompanyChanged: true,
        mustHaveSelectedDeclaration: true,
      },
      children: [
        {
          path: '',
          name: Router.DECLARATION_SUMMARY + '-resume',
          component: DeclarationSummary,
          meta: {
            needSelectedCompany: true,
            mustReturnHomeIfCompanyChanged: true,
            mustHaveSelectedDeclaration: true,
          },
        },
        {
          path: 'individuelle',
          name: Router.INDIVIDUAL_DECLARATION,
          component: IndividualDeclaration,
          meta: {
            needSelectedCompany: true,
            mustReturnHomeIfCompanyChanged: true,
            shouldSaveForm: true,
          },
        },
        {
          path: 'entreprise',
          name: Router.COMPANY + '_DECLARATION',
          component: CompanyDeclaration,
          meta: {
            needSelectedCompany: true,
            mustReturnHomeIfCompanyChanged: true,
            shouldSaveForm: true,
          },
        },
        {
          path: 'regularisation',
          name: Router.REGULARIZATION,
          component: RegularizationDeclaration,
          meta: {
            needSelectedCompany: true,
            mustReturnHomeIfCompanyChanged: true,
            shouldSaveForm: true,
          },
        },
      ],
    },
    {
      path: '/signalement-arret-de-travail',
      name: Router.REPORT_WORK_STOPPAGE,
      component: ReportingWorkingStoppage,
      meta: {
        needSelectedCompany: true,
        mustReturnHomeIfCompanyChanged: true,
      },
    },
    {
      path: '/signalement-fin-de-contrat',
      name: Router.REPORT_ENDING_CONTRACT,
      component: EndingContractForm,
      meta: {
        needSelectedCompany: true,
        mustReturnHomeIfCompanyChanged: true,
      },
      children: [
        {
          path: '',
          name: Router.REPORT_ENDING_CONTRACT + '-info',
          component: GlobalInfosEndingContractStep,
          meta: {
            isFirstStep: true,
            needSelectedCompany: true,
            mustReturnHomeIfCompanyChanged: true,
          },
        },
        {
          path: 'revenus-dernier-mois',
          name: Router.LAST_MONTH_REVENUE,
          component: LastMonthRevenuStep,
          meta: {
            needSelectedCompany: true,
            mustReturnHomeIfCompanyChanged: true,
          },
        },
        {
          path: 'informations-supplementaires',
          name: Router.STEP_ADDITIONAL_INFORMATIONS,
          component: ComplementaryInfosStep,
          meta: {
            isLastStep: true,
            needSelectedCompany: true,
            mustReturnHomeIfCompanyChanged: true,
          },
        },
      ],
    },
    {
      path: '/signalement-arret-de-travail',
      name: Router.REPORT_WORK_STOPPAGE,
      component: ReportingWorkingStoppage,
      meta: {
        mustReturnHomeIfCompanyChanged: true,
        needSelectedCompany: true,
      },
    },
  ],
})

router.beforeEach((to, from) => {
  const companyStore = useCompanyStore()
  const declarationStore = useDeclarationStore()
  const mainStore = useMainStore()
  mainStore.showSavingWarning = false
  mainStore.canceledRoute = ''

  if (to.meta.needSelectedCompany && !companyStore.company.id) {
    if (companyStore.selectedCompanyBeforeCreation) companyStore.company.id = companyStore.selectedCompanyBeforeCreation
    else {
      mainStore.forceSelectionCompany = true
    }
  } else {
    mainStore.forceSelectionCompany = false
  }

  if (to.meta.mustHaveSelectedDeclaration && !declarationStore.declaration.id) {
    mainStore.serverResponseStatut.infoMessage =
      "Vous ne pouvez pas revenir au résumé, car plus aucune période n'est sélectionnée."
    return false
  }

  if (from.path !== to.path && from.meta.shouldSaveForm && mainStore.formHaveBeenModified) {
    mainStore.showSavingWarning = true
    mainStore.canceledRoute = to.path
    return false
  }
})

router.afterEach((to, from) => {
  const mainStore = useMainStore()
  mainStore.routerSourcePage = from.path as Router
})

router.onError((error) => {
  const isImportedModuleError =
    error.message.includes('dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('preload CSS')

  if (isImportedModuleError) {
    showToastAndReloadOneTime(`Une nouvelle version de l'application est disponible, la page va être actualisée (↻).`)
  } else {
    useMainStore().addErrorIfNotAlreadyPresent({
      value: "Une erreur est survenue, n'hésitez pas à actualiser (↻) la page si cela persiste.",
    })
  }
})

export const showToastAndReloadOneTime = (error: string, retries = 1) => {
  useMainStore().serverResponseStatut.infoMessage = error
  // Get the current URL
  const urlString = window.location.href

  // Create a URL object
  const url = new URL(urlString)
  const errRetries = parseInt(url.searchParams.get('tentatives') || '0')
  if (errRetries >= retries) {
    window.history.replaceState(null, '', url.pathname)
    return
  }

  // Update or add the query parameter
  url.searchParams.set('tentatives', String(errRetries + 1))

  // Reload the page with the updated URL
  setTimeout(() => {
    window.location.href = url.toString()
  }, 3000)
}
