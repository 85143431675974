import { dateFRtoBasic } from '@/utils/index'
import { Locality } from './company.model'
import { Declaration, DeclarationDashboard, ReportDashboard } from './declaration.model'
import {
  Invoices,
  InvoicesResponse,
  Subscription,
  SubscriptionInfoResponse,
  SubscriptionResponse,
  SubscriptionServicePaymentPayload,
  Subscriptions,
  CompaniesResponse,
  Companies,
  STATUS_COMPANY,
} from './main.model'
import { determineMonthLabelPeriod } from '@/utils'

export class Helper {
  static storingSubscriptionInfosFromAPI(subscriptionInfo: SubscriptionInfoResponse) {
    const subscriptionForStore: Subscription = {
      awaitingPaymentWithoutCollection: subscriptionInfo.existeAttentePaiementSansEncaissement,
      cbProvider: subscriptionInfo.cbProvider,
      companyBillingInfos: {
        name: subscriptionInfo.facturationEntreprise.nom,
        street: subscriptionInfo.facturationEntreprise.voie,
        zipCode: subscriptionInfo.facturationEntreprise.codePostal,
        locality: subscriptionInfo.facturationEntreprise.localite,
        country: subscriptionInfo.facturationEntreprise.pays,
      },
      invoiceView: {
        title: subscriptionInfo.factureView.titre,
        duration: subscriptionInfo.factureView.duree,
        amountIncludingAllTaxes: subscriptionInfo.factureView.totalTtc,
        amountWithoutTax: subscriptionInfo.factureView.totalHt,
        taxAmount: subscriptionInfo.factureView.montantTva,
        taxRate: subscriptionInfo.factureView.tauxTva,
        totalCentsTtc: subscriptionInfo.factureView.totalCentimesTtc,
        paymentType: subscriptionInfo.factureView.typePaiement,
        dateInvoice: subscriptionInfo.factureView.dateFacture,
      },
      braintree: {
        clientToken: subscriptionInfo.braintree.clientToken,
      },
    }
    return subscriptionForStore
  }

  static constructConfirmPaymentPayload(
    subscription: Subscription,
    locality: Locality,
    paymentParams: { braintreeNonce: any; payplugToken: string },
  ): SubscriptionServicePaymentPayload {
    return {
      cbProvider: subscription.cbProvider,
      nomFacture: subscription.companyBillingInfos.name,
      addressStreet: subscription.companyBillingInfos.street,
      complementAdresse: locality.address.addressSupplement,
      addressPostalCode: subscription.companyBillingInfos.zipCode,
      addressCity: subscription.companyBillingInfos.locality,
      addressCountry: subscription.companyBillingInfos.country || 'FR',
      numeroTva: '',
      paymentType: subscription.invoiceView.paymentType,
      firstName: '',
      lastName: '',
      braintreeNonce: paymentParams.braintreeNonce.nonce,
      braintreeDeviceData: '',
      payplugToken: paymentParams.payplugToken,
    }
  }

  static transformDeclarationsToDeclarationsDashboard(payload: Declaration[]) {
    const declarations: DeclarationDashboard[] = []

    payload.forEach((declaration) => {
      declarations.push({
        id: declaration.id,
        status: declaration.status,
        sendingStatus: declaration.statutEnvoi,
        statusLabel: declaration.libelleStatus,
        period: determineMonthLabelPeriod(declaration.periode),
        rawPeriod: declaration.periode,
        exigibility: declaration.exigibilite,
        nature: declaration.nature,
        exigibilityOutdated: declaration.exigibilteAtteinte,
        exigibilityLabel: declaration.libelleExigibilite,
        editable: declaration.modifiable,
        lastSendingDate: declaration.dateDernierEnvoi,
        lastSendingId: declaration.dernierEnvoiId,
        canBeReopened: declaration.modifiable,
        returnDeadlineDate: declaration.dateLimiteRenvoi,
      })
    })

    return declarations
  }

  static transformDeclarationsToReportsDashboard(payload: Declaration[]) {
    const reports: ReportDashboard[] = []

    payload.forEach((declaration) => {
      reports.push({
        id: declaration.id,
        status: declaration.status,
        sendingStatus: declaration.statutEnvoi,
        statusLabel: declaration.libelleStatus,
        period: new Date(dateFRtoBasic(declaration.periode)),
        rawPeriod: declaration.periode,
        nature: declaration.nature,
        name: declaration.nom,
        firstName: declaration.prenoms,
        exigibilityLabel: declaration.libelleExigibilite,
        editable: declaration.modifiable,
        lastSendingDate: declaration.dateDernierEnvoi,
        lastSendingId: declaration.dernierEnvoiId,
        resent: declaration.renvoi,
      })
    })

    return reports
  }

  static storingInvoicesFromAPI(payload: InvoicesResponse[]) {
    const invoices: Invoices[] = []

    payload.forEach((invoice) => {
      invoices.push({
        companyId: invoice.entreprise_id,
        subscriptionId: invoice.abo_id,
        subscriptionOrRenewingDate: new Date(dateFRtoBasic(invoice.date_abo_ou_renouvellement)),
        paymentDate: invoice.date_reglement ? new Date(dateFRtoBasic(invoice.date_reglement)) : null,
        companyName: invoice.raison_sociale,
        paymentType: invoice.type_paiement,
      })
    })

    return invoices
  }

  static storingSubscriptionsFromAPI(payload: SubscriptionResponse[]) {
    const subscriptions: Subscriptions[] = []

    payload.forEach((subscription) => {
      subscriptions.push({
        companyId: subscription.entreprise_id,
        companyName: subscription.raison_sociale,
        expirationDate: new Date(dateFRtoBasic(subscription.date_expiration)),
        firstSubscriptionDate: new Date(dateFRtoBasic(subscription.premier_abonnement)),
        siret: subscription.siret,
        status: { code: subscription.status.code, label: subscription.status.label },
      })
    })

    return subscriptions
  }

  static storingCompaniesFromAPI(payload: CompaniesResponse[]) {
    const companies: Companies[] = []

    payload.forEach((companie) => {
      companies.push({
        id: companie.entreprise_id,
        name: companie.raison_sociale,
        siret: companie.siret,
        status: this.determineCompanyStatus(companie),
        deletable: companie.supprimable,
        isSubscriptionActive: companie.abonnementActif,
      })
    })

    return companies
  }

  static determineCompanyStatus(company: CompaniesResponse): STATUS_COMPANY {
    if (!company.status) {
      return STATUS_COMPANY.CREATION_IN_PROGRESS
    } else if (company.status && !company.abonnementActif) {
      return STATUS_COMPANY.NOT_SUBSCRIBED
    }
    return STATUS_COMPANY.ACTIVE
  }
}
