<script setup lang="ts">
import CompaniesDropdown from './CompaniesDropdown.vue'
import { onMounted, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { Router } from '@/models/router.model'
import { useCompanyStore } from '@/stores/company'
import { useEmployeeStore } from '@/stores/employee'
import { useMainStore } from '@/stores/main'
import AssistanceModal from '@/components/commons/AssistanceModal.vue'
import ConfirmationChangingCompanyModal from '@/components/commons/ConfirmationChangingCompanyModal.vue'
import ForceSelectionCompanyModal from './ForceSelectionCompanyModal.vue'
import { COMPANY_NOTIFICATION_CODE } from '@/models/company.model'
import { useConfirm } from 'primevue/useconfirm'
import { Companies } from '@/models/main.model'
import { useUtilsStore } from '@/stores/utils'

const mainStore = useMainStore()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const employeeStore = useEmployeeStore()
const utilsStore = useUtilsStore()
const route = useRoute()
const confirm = useConfirm()

const companyId = computed(() => companyStore.company.id)
const notifications = computed(() => companyStore.company.notifications)
const loading = computed(() => mainStore.loading)
const forceSelectionCompany = computed(() => mainStore.forceSelectionCompany)

const selectedCompany = ref(mainStore.selectedCompany)
const showAssistanceModal = ref(false)
const smallMenu = ref()
const notificationsOverlay = ref()
const previousSelectedCompany = ref<Companies | null>(null)
const showConfirmationChangingCompanyModal = ref(false)
const showForceSelectionCompanyModal = ref(false)
const isMinifyMode = ref(utilsStore.sideBarIsMinify)
const isHover = ref(false)
let hoverTimeout: ReturnType<typeof setTimeout> | null = null

onMounted(() => {
  if (companyId.value) {
    selectedCompany.value = mainStore.getCompanyById(companyId.value)
    companyStore.getCompanyDetails()
    employeeStore.getCompanyEmployees(companyId.value)
    previousSelectedCompany.value = selectedCompany.value
  } else {
    mainStore.selectedCompany = null
    mainStore.forceSelectionCompany = true
    showForceSelectionCompanyModal.value = true
  }
})

watch(
  () => companyStore.company.id,
  (companyId) => {
    if (companyId) {
      mainStore.resetServerMessages()
      selectedCompany.value = mainStore.getCompanyById(companyId)
      companyStore.getCompanyDetails()
      employeeStore.getCompanyEmployees(companyId)
    } else selectedCompany.value = null
  },
)

watch(selectedCompany, (selectedCompanyValue, oldSelectedCompany) => {
  if (selectedCompanyValue) {
    if (route.meta.mustReturnHomeIfCompanyChanged && selectedCompanyValue !== previousSelectedCompany.value) {
      previousSelectedCompany.value = oldSelectedCompany
      showConfirmationChangingCompanyModal.value = true
    } else {
      companyStore.company.id = selectedCompanyValue.id
      mainStore.selectedCompany = selectedCompanyValue
      mainStore.forceSelectionCompany = false
    }
  } else {
    selectedCompany.value = null
    companyStore.company.id = null
    companyStore.selectedCompanyBeforeCreation = null
  }
})

watch(forceSelectionCompany, () => {
  showForceSelectionCompanyModal.value = forceSelectionCompany.value
})

watch(isMinifyMode, () => {
  utilsStore.sideBarIsMinify = isMinifyMode.value
})

const showNoficationsOverlay = (event: any) => {
  notificationsOverlay.value.toggle(event)
}

const generateMenuOptions = () => {
  const items = [
    {
      label: 'Tableau de bord',
      items: [
        {
          label: 'Mes déclarations',
          icon: 'pi pi-calendar-clock',
          route: Router.DASHBOARD + Router.DECLARATIONS,
        },
        {
          label: 'Mes salariés',
          icon: 'pi pi-users',
          route: Router.DASHBOARD + Router.EMPLOYEES,
        },
        {
          label: 'Mes signalements',
          icon: 'pi pi-exclamation-triangle',
          route: Router.DASHBOARD + Router.REPORTS,
        },
        {
          label: 'Paiements aux organismes',
          icon: 'pi pi-building-columns',
          route: Router.DASHBOARD + Router.ORGANIZATIONS_PAYMENTS,
        },
        {
          label: 'Salaires versés',
          icon: 'pi pi-euro',
          route: Router.DASHBOARD + Router.SALARIES_PAID,
        },
      ],
    },
    {
      label: 'Entreprise',
      items: [
        {
          label: 'Modifier les informations',
          icon: 'pi pi-pencil',
          route: Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS,
        },
      ],
    },
    {
      label: 'Mon compte',
      items: [
        {
          label: 'Mes identifiants',
          icon: 'pi pi-user',
          route: Router.MY_ACCOUNT + Router.MY_IDENTIFIERS,
        },
        {
          label: 'Mes abonnements',
          icon: 'pi pi-euro',
          route: Router.MY_ACCOUNT + Router.MY_SUBSCRIPTIONS,
        },
        {
          label: 'Mes factures',
          icon: 'pi pi-file',
          route: Router.MY_ACCOUNT + Router.MY_BILLS,
        },
        {
          label: 'Mes entreprises',
          icon: 'pi pi-building',
          route: Router.MY_ACCOUNT + Router.MY_COMPANIES,
        },
        {
          label: 'Mes paramètres',
          icon: 'pi pi-cog',
          route: Router.MY_ACCOUNT + Router.MY_SETTINGS,
        },
      ],
    },
  ]
  return items
}

const confirmLogout = (event: any) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Voulez-vous vous déconnecter ?',
    acceptIcon: 'pi pi-check',
    rejectIcon: 'pi pi-times',
    acceptLabel: 'Confirmer',
    rejectLabel: 'Annuler',
    rejectClass: 'p-button-outlined p-button-sm',
    acceptClass: 'p-button-sm',
    accept: () => {
      authStore.logout()
    },
  })
}

const hovering = (value: boolean) => {
  if (hoverTimeout) clearTimeout(hoverTimeout)
  hoverTimeout = setTimeout(() => (isHover.value = value), 200)
}
</script>

<template>
  <Sidebar
    :modal="false"
    :dismissable="false"
    :showCloseIcon="false"
    :class="isMinifyMode && !isHover ? 'w-min fadeinright animation-duration-800' : 'fadeinleft animation-duration-900'"
  >
    <template #container>
      <div class="flex flex-column h-full" @mouseenter="hovering(true)" @mouseleave="hovering(false)">
        <div class="flex justify-content-center my-3">
          <router-link to="/" class="no-underline">
            <img
              v-if="(isMinifyMode && isHover) || !isMinifyMode"
              src="@/assets/img/logo.png"
              class="height-logo hidden lg:flex"
              alt="Teledsn logo"
              :class="{ 'w-min': (isMinifyMode && isHover) || !isMinifyMode }"
            />
            <span v-else class="block text-lg height-logo text-warning text-center font-monospace"
              >Télé<br /><b>DSN</b></span
            >
          </router-link>
        </div>
        <CompaniesDropdown
          v-model:selectedCompany="selectedCompany"
          :isMinify="isMinifyMode && !isHover"
          class="onboarding-step-3"
          :class="{ 'w-min': isMinifyMode && !isHover, 'w-full': !isMinifyMode || isHover }"
        />
        <div class="flex flex-column gap-3 h-full py-3 justify-content-between overflow-y-auto">
          <Menu
            ref="smallMenu"
            :model="generateMenuOptions()"
            id="small-menu"
            :class="{ 'w-min min-w-0 mx-auto': isMinifyMode && !isHover }"
          >
            <template #submenuheader="{ item }">
              <div class="flex align-items-center justify-content-between" v-if="!isMinifyMode || isHover">
                <span class="text-primary font-bold">{{ item.label }}</span>
                <Button
                  v-if="item.label === 'Entreprise' && notifications && notifications.length > 0"
                  @click="showNoficationsOverlay($event)"
                  text
                  rounded
                  severity="secondary"
                  v-tippy="`Afficher les notifications liées à l'entreprise`"
                  class="p-0 overflow-visible"
                >
                  <i v-badge.info="notifications.length" class="pi pi-bell text-xl" />
                </Button>
              </div>
              <hr v-else class="border-primary" />
            </template>
            <template #item="{ item, props }">
              <router-link v-if="item.route" :to="item.route" class="flex align-items-center" v-bind="props.action">
                <span :class="`${item.icon} text-lg`" />
                <span class="ml-2" v-if="(isMinifyMode && isHover) || !isMinifyMode">{{ item.label }}</span>
              </router-link>
            </template>
          </Menu>
          <div class="flex flex-column gap-3" :class="isMinifyMode && !isHover ? 'px-0' : 'px-2'">
            <Button
              @click="showAssistanceModal = true"
              label="Besoin d'aide"
              icon="pi pi-question-circle"
              severity="help"
              rounded
              size="small"
              :outlined="!isMinifyMode || isHover"
              :text="isMinifyMode && !isHover"
              class="w-full"
              v-tippy="`Accès au guide utilisateur, à la FAQ, ou au formulaire de contact`"
            >
              <span class="p-button-icon pi pi-question-circle text-lg" />
              <span class="p-button-label" v-if="(isMinifyMode && isHover) || !isMinifyMode">Besoin d'aide</span>
            </Button>
            <Button
              @click="confirmLogout"
              label="Se déconnecter"
              icon="pi pi-sign-out"
              severity="danger"
              size="small"
              rounded
              :outlined="!isMinifyMode || isHover"
              :text="isMinifyMode && !isHover"
              class="w-full"
            >
              <span class="p-button-icon pi pi-sign-out text-lg" />
              <span class="p-button-label" v-if="(isMinifyMode && isHover) || !isMinifyMode">Se déconnecter</span>
            </Button>
          </div>
        </div>
      </div>
      <Button
        @click="isMinifyMode = !isMinifyMode"
        :icon="isMinifyMode ? 'pi pi-chevron-right' : 'pi pi-chevron-left'"
        severity="contrast"
        rounded
        size="small"
        class="minify-btn"
      />
      <OverlayPanel ref="notificationsOverlay" class="overlay-min-width">
        <div class="flex flex-column gap-3">
          <u class="w-full text-lg">Notifications :</u>
          <div v-for="(notification, index) in notifications" :key="notification.code" class="w-full">
            <InlineMessage
              v-if="notification.code === COMPANY_NOTIFICATION_CODE.CONFIGURATION_SHEET_MAJ"
              :severity="!companyStore.complementaryOrganismsAvailable() ? 'warn' : 'info'"
            >
              Votre fiche paramétrage a récemment été mise à jour, pensez à vérifier les affiliations de vos salariés.
              <p v-if="!companyStore.complementaryOrganismsAvailable()" class="mb-0">
                Vous devriez certainement ajouter l'organisme <b>« ORGANISME COMPLÉMENTAIRE »</b> dans la fiche
                entreprise (étape 3).
              </p>
            </InlineMessage>
            <p v-else>
              {{ notification.libelle }}
            </p>
            <hr v-if="index !== notifications.length - 1" />
          </div>
        </div>
      </OverlayPanel>
      <AssistanceModal v-if="!loading" v-model:visible="showAssistanceModal" />
      <ForceSelectionCompanyModal
        v-if="!loading"
        :visible="showForceSelectionCompanyModal"
        v-model:selectedCompany="selectedCompany"
      />
      <ConfirmationChangingCompanyModal
        v-if="!loading"
        v-model:visible="showConfirmationChangingCompanyModal"
        v-model:newSelectedCompany="selectedCompany"
        v-model:oldSelectedCompany="previousSelectedCompany"
      />
    </template>
  </Sidebar>
</template>

<style scoped>
.height-logo {
  height: 50px;
}

.minify-btn {
  position: absolute;
  right: -27px;
  bottom: 50%;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding: 6px;
  padding-left: 4px;
  height: 2rem !important;
  width: min-content;
}

.font-monospace {
  font-family: monospace;
}
</style>
