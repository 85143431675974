import { defineStore } from 'pinia'

type State = {
  rowsPerPageOptions: number[]
  rowsPerPage: number
  isDarkMode: boolean
  displayMode: 'COMPACT' | 'NORMAL'
  displayMenuInDeclaration: boolean
  sideBarIsMinify: boolean
}

export const useUtilsStore = defineStore('utilsStore', {
  state: (): State => ({
    rowsPerPageOptions: [5, 8, 10, 15, 20, 25, 30, 50, 75, 100],
    rowsPerPage: 10,
    isDarkMode: false,
    displayMode: 'NORMAL',
    displayMenuInDeclaration: false,
    sideBarIsMinify: false,
  }),
  getters: {
    isCompactDisplayMode() {
      return () => this.displayMode === 'COMPACT'
    },
  },
  actions: {
    setRows(rowPerPage: number) {
      this.rowsPerPage = rowPerPage
    },
  },
  persist: true,
})
