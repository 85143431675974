import { dateToFRFormat, dateFRtoBasic } from '@/utils/index'
import { Employee, EmployeeInfos, EmployeeLocality, EmployeePayload } from './employee.model'

export class Helper {
  static storingEmployee(employee: Employee): {
    id: number
    infos: EmployeeInfos
    locality: EmployeeLocality
    deletable: boolean
  } {
    return {
      id: employee.salarieId,
      infos: {
        civility: '',
        birthCountry: null,
        nationality: '',
        firstname: employee.prenoms,
        lastname: employee.nom,
        birthdate: null,
        birthCity: '',
        socialSecurityNumber: employee.numeroSecuriteSociale,
        companySerialNumber: '',
        nttNumber: employee.numeroNTT,
      },
      locality: {
        zipCode: '',
        selectedCity: null,
        selectedCountry: 'FR',
        address: {
          street: '',
          addressSupplement: '',
          namedPlace: '',
        },
      },
      deletable: employee.supprimable ?? false,
    }
  }

  static storingEmployeeInfosFromAPI(employee: EmployeePayload): EmployeeInfos {
    return {
      civility: employee.civilite,
      birthCountry: employee.paysNaissance === '' ? 'FR' : employee.paysNaissance,
      firstname: employee.prenoms,
      lastname: employee.nom,
      birthdate: new Date(dateFRtoBasic(employee.dateNaissance)),
      birthCity: employee.lieuNaissance,
      socialSecurityNumber: employee.numeroSecu ?? '',
      companySerialNumber: employee.matricule,
      nationality: employee.codeNationalite,
      nttNumber: employee.numNtt,
    }
  }

  static storingEmployeeLocalityFromAPI(employee: EmployeePayload): EmployeeLocality {
    return {
      zipCode: employee.cp,
      selectedCity: employee.localite?.toUpperCase() ?? null,
      selectedCountry: employee.codePays === '' ? 'FR' : employee.codePays,
      address: {
        street: employee.numeroVoie,
        addressSupplement: employee.complementAdresse,
        namedPlace: employee.complementAdresse2,
      },
    }
  }

  static constructEmployeePayload(infos: EmployeeInfos, locality: EmployeeLocality): EmployeePayload {
    return {
      numeroSecu: infos.socialSecurityNumber ? infos.socialSecurityNumber.replaceAll(' ', '') : null,
      numNtt: infos.nttNumber ?? null,
      matricule: infos.companySerialNumber,
      civilite: infos.civility,
      codeNationalite: infos.nationality,
      nom: infos.lastname,
      prenoms: infos.firstname,
      departementNaissance: null,
      dateNaissance: infos.birthdate ? dateToFRFormat(infos.birthdate) : '',
      lieuNaissance: infos.birthCity,
      paysNaissance: infos.birthCountry === 'FR' ? '' : infos.birthCountry,
      numeroVoie: locality.address.street,
      complementAdresse: locality.address.addressSupplement,
      complementAdresse2: locality.address.namedPlace,
      cp: locality.zipCode,
      localite: locality.selectedCity,
      codePays: locality.selectedCountry === 'FR' ? '' : locality.selectedCountry,
    }
  }
}
