<script setup lang="ts">
type Props = {
  dontFixContent?: boolean
}
type EmitsType = {
  (e: 'scrolling', value: Event): void
}

const props = defineProps<Props>()
const emit = defineEmits<EmitsType>()
</script>
<template>
  <div
    @scroll="emit('scrolling', $event)"
    class="grid grid-nogutter row-gap-4"
    :class="{
      'special-height mb-7 md:mb-0': !props.dontFixContent,
      'mb-7': props.dontFixContent,
    }"
  >
    <slot />
  </div>
</template>
<style scoped>
@media screen and (min-width: 768px) {
  .special-height {
    overflow: auto;
    scroll-behavior: smooth;
  }
}

@media screen and (min-height: 500px) and (min-width: 768px) {
  .special-height {
    height: 39vh;
    margin-bottom: 4rem;
  }
}

@media screen and (min-height: 500px) and (min-width: 1400px) {
  .special-height {
    height: 53vh;
  }
}

@media screen and (min-height: 550px) and (min-width: 768px) {
  .special-height {
    height: 46vh;
    margin-bottom: 4rem;
  }
}

@media screen and (min-height: 550px) and (min-width: 1400px) {
  .special-height {
    height: 57vh;
  }
}

@media screen and (min-height: 600px) and (min-width: 768px) {
  .special-height {
    height: 48vh;
  }
}

@media screen and (min-height: 600px) and (min-width: 1400px) {
  .special-height {
    height: 61vh;
  }
}

@media screen and (min-height: 650px) and (min-width: 768px) {
  .special-height {
    height: 53vh;
  }
}

@media screen and (min-height: 650px) and (min-width: 1400px) {
  .special-height {
    height: 64vh;
  }
}

@media screen and (min-height: 700px) and (min-width: 768px) {
  .special-height {
    height: 55vh;
  }
}

@media screen and (min-height: 700px) and (min-width: 1400px) {
  .special-height {
    height: 66vh;
  }
}

@media screen and (min-height: 750px) and (min-width: 768px) {
  .special-height {
    height: 58vh;
  }
}

@media screen and (min-height: 750px) and (min-width: 1400px) {
  .special-height {
    height: 68vh;
  }
}

@media screen and (min-height: 800px) and (min-width: 768px) {
  .special-height {
    height: 61vh;
  }
}

@media screen and (min-height: 800px) and (min-width: 1400px) {
  .special-height {
    height: 70vh;
  }
}

@media screen and (min-height: 850px) and (min-width: 768px) {
  .special-height {
    height: 64vh;
  }
}

@media screen and (min-height: 850px) and (min-width: 1400px) {
  .special-height {
    height: 72vh;
  }
}

@media screen and (min-height: 900px) and (min-width: 768px) {
  .special-height {
    height: 66vh;
  }
}

@media screen and (min-height: 900px) and (min-width: 1400px) {
  .special-height {
    height: 73vh;
  }
}

@media screen and (min-height: 950px) and (min-width: 768px) {
  .special-height {
    height: 68vh;
  }
}

@media screen and (min-height: 950px) and (min-width: 1400px) {
  .special-height {
    height: 75vh;
  }
}

@media screen and (min-height: 1000px) and (min-width: 768px) {
  .special-height {
    height: 69vh;
  }
}

@media screen and (min-height: 1000px) and (min-width: 1400px) {
  .special-height {
    height: 76vh;
  }
}

@media screen and (min-height: 1050px) and (min-width: 768px) {
  .special-height {
    height: 70vh;
  }
}

@media screen and (min-height: 1050px) and (min-width: 1400px) {
  .special-height {
    height: 77vh;
  }
}

@media screen and (min-height: 1100px) and (min-width: 768px) {
  .special-height {
    height: 71vh;
  }
}

@media screen and (min-height: 1100px) and (min-width: 1400px) {
  .special-height {
    height: 78vh;
  }
}

@media screen and (min-height: 1150px) and (min-width: 768px) {
  .special-height {
    height: 73vh;
  }
}

@media screen and (min-height: 1150px) and (min-width: 1400px) {
  .special-height {
    height: 79vh;
  }
}

@media screen and (min-height: 1200px) and (min-width: 768px) {
  .special-height {
    height: 74vh;
  }
}

@media screen and (min-height: 1200px) and (min-width: 1400px) {
  .special-height {
    height: 80vh;
  }
}

@media screen and (min-height: 1250px) and (min-width: 768px) {
  .special-height {
    height: 75vh;
  }
}

@media screen and (min-height: 1250px) and (min-width: 1400px) {
  .special-height {
    height: 80vh;
  }
}

@media screen and (min-height: 1300px) and (min-width: 768px) {
  .special-height {
    height: 76vh;
  }
}

@media screen and (min-height: 1300px) and (min-width: 1400px) {
  .special-height {
    height: 81vh;
  }
}

@media screen and (min-height: 1350px) and (min-width: 768px) {
  .special-height {
    height: 77vh;
  }
}

@media screen and (min-height: 1350px) and (min-width: 1400px) {
  .special-height {
    height: 82vh;
  }
}

@media screen and (min-height: 1400px) and (min-width: 768px) {
  .special-height {
    height: 78vh;
  }
}

@media screen and (min-height: 1400px) and (min-width: 1400px) {
  .special-height {
    height: 83vh;
  }
}

@media screen and (min-height: 1450px) and (min-width: 768px) {
  .special-height {
    height: 79vh;
  }
}

@media screen and (min-height: 1450px) and (min-width: 1400px) {
  .special-height {
    height: 84vh;
  }
}

@media screen and (min-height: 1500px) and (min-width: 768px) {
  .special-height {
    height: 80vh;
  }
}

@media screen and (min-height: 1500px) and (min-width: 1400px) {
  .special-height {
    height: 84vh;
  }
}

@media screen and (min-height: 1600px) and (min-width: 768px) {
  .special-height {
    height: 81vh;
  }
}

@media screen and (min-height: 1600px) and (min-width: 1400px) {
  .special-height {
    height: 85vh;
  }
}

@media screen and (min-height: 1700px) and (min-width: 1400px) {
  .special-height {
    height: 86vh;
  }
}

@media screen and (min-height: 1800px) and (min-width: 768px) {
  .special-height {
    height: 82vh;
  }
}

@media screen and (min-height: 1800px) and (min-width: 1400px) {
  .special-height {
    height: 87vh;
  }
}

@media screen and (min-height: 2000px) and (min-width: 1400px) {
  .special-height {
    height: 88vh;
  }
}

@media screen and (min-height: 2150px) and (min-width: 1400px) {
  .special-height {
    height: 89vh;
  }
}

@media screen and (min-height: 2350px) and (min-width: 1400px) {
  .special-height {
    height: 90vh;
  }
}
</style>
